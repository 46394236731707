!(function (t, e) {
    'object' == typeof exports && 'undefined' != typeof module ? e(exports) : 'function' == typeof define && define.amd ? define(['exports'], e) : e(((t = 'undefined' != typeof globalThis ? globalThis : t || self).littlefoot = {}));
})(this, function (t) {
    'use strict';
    var e = function () {
        return (
            (e =
                Object.assign ||
                function (t) {
                    for (var e, n = 1, r = arguments.length; n < r; n++) for (var i in (e = arguments[n])) Object.prototype.hasOwnProperty.call(e, i) && (t[i] = e[i]);
                    return t;
                }),
            e.apply(this, arguments)
        );
    };
    'function' == typeof SuppressedError && SuppressedError;
    var n = {};
    Object.defineProperty(n, '__esModule', {value: !0});
    var r = (n.getStyle = void 0);
    r = n.getStyle = function (t, e) {
        var n,
            r = ((null === (n = t.ownerDocument) || void 0 === n ? void 0 : n.defaultView) || window).getComputedStyle(t);
        return r.getPropertyValue(e) || r[e];
    };
    var i = {};
    Object.defineProperty(i, '__esModule', {value: !0});
    var o = (i.pixels = void 0),
        a = n,
        u = 96,
        c = 25.4;
    function l(t) {
        return t ? (0, a.getStyle)(t, 'fontSize') || l(t.parentElement) : (0, a.getStyle)(window.document.documentElement, 'fontSize');
    }
    function s(t, e) {
        t.classList.add(e);
    }
    function f(t, e) {
        t.classList.remove(e);
    }
    function d(t) {
        var e;
        null === (e = t.parentNode) || void 0 === e || e.removeChild(t);
    }
    o = i.pixels = function t(e, n) {
        var r,
            i,
            o = null !== (i = null === (r = null == n ? void 0 : n.ownerDocument) || void 0 === r ? void 0 : r.defaultView) && void 0 !== i ? i : window,
            a = o.document.documentElement || o.document.body,
            s = (function (t) {
                var e,
                    n = t || '0',
                    r = parseFloat(n),
                    i = n.match(/[\d-.]+(\w+)$/);
                return [r, (null !== (e = null == i ? void 0 : i[1]) && void 0 !== e ? e : '').toLowerCase()];
            })(e),
            f = s[0];
        switch (s[1]) {
            case 'rem':
                return f * t(l(window.document.documentElement));
            case 'em':
                return f * t(l(n), null == n ? void 0 : n.parentElement);
            case 'in':
                return f * u;
            case 'q':
                return (f * u) / c / 4;
            case 'mm':
                return (f * u) / c;
            case 'cm':
                return (f * u * 10) / c;
            case 'pt':
                return (f * u) / 72;
            case 'pc':
                return (f * u) / 6;
            case 'vh':
                return (f * o.innerHeight || a.clientWidth) / 100;
            case 'vw':
                return (f * o.innerWidth || a.clientHeight) / 100;
            case 'vmin':
                return (f * Math.min(o.innerWidth || a.clientWidth, o.innerHeight || a.clientHeight)) / 100;
            case 'vmax':
                return (f * Math.max(o.innerWidth || a.clientWidth, o.innerHeight || a.clientHeight)) / 100;
            default:
                return f;
        }
    };
    var v = 'littlefoot__tooltip',
        m = 'is-';
    function p(t) {
        var e = t.offsetHeight,
            n = t.getBoundingClientRect().top + e / 2;
        return {above: n, below: window.innerHeight - n};
    }
    function h(t) {
        var e = parseFloat(r(t, 'marginLeft')),
            n = t.offsetWidth - e;
        return (t.getBoundingClientRect().left + n / 1) / window.innerWidth;
    }
    function g(t, e) {
        var n = 2 * parseInt(r(t, 'marginTop'), 10) + t.offsetHeight;
        return e.below < n && e.below < e.above ? 'above' : 'below';
    }
    var y = 'is-active',
        b = 'is-changing',
        w = 'is-scrollable',
        E = function (t) {
            return !!t.parentElement;
        };
    function H(t) {
        var e = t.id,
            n = t.button,
            i = t.content,
            a = t.host,
            u = t.popover,
            c = t.wrapper,
            l = !1,
            H = 0,
            x = 'above';
        return {
            id: e,
            activate: function (t) {
                var e;
                n.setAttribute('aria-expanded', 'true'), s(n, b), s(n, y), n.insertAdjacentElement('afterend', u), (u.style.maxWidth = document.body.clientWidth + 'px'), (e = i), (H = Math.round(o(r(e, 'maxHeight'), e))), null == t || t(u, n);
            },
            dismiss: function (t) {
                n.setAttribute('aria-expanded', 'false'), s(n, b), f(n, y), f(u, y), null == t || t(u, n);
            },
            isActive: function () {
                return n.classList.contains(y);
            },
            isReady: function () {
                return !n.classList.contains(b);
            },
            isHovered: function () {
                return l;
            },
            ready: function () {
                s(u, y), f(n, b);
            },
            remove: function () {
                d(u), f(n, b);
            },
            reposition: function () {
                E(u) &&
                    ((i.style.maxHeight =
                        (function (t, e, n) {
                            var i = p(e),
                                o = g(t, i),
                                a = parseInt(r(t, 'marginTop'), 10);
                            return Math.min(n, i[o] - a - 15);
                        })(u, n, H) + 'px'),
                    (x = (function (t, e, n) {
                        var r = g(t, p(e));
                        if (n !== r) {
                            f(t, m + n), s(t, m + r);
                            var i = 100 * h(e) + '%',
                                o = 'above' === r ? '100%' : '0';
                            t.style.transformOrigin = i + ' ' + o;
                        }
                        return r;
                    })(u, n, x)),
                    u.offsetHeight < i.scrollHeight ? (s(u, w), i.setAttribute('tabindex', '0')) : (f(u, w), i.removeAttribute('tabindex')));
            },
            resize: function () {
                E(u) &&
                    ((u.style.left =
                        (function (t, e) {
                            var n = t.offsetWidth;
                            return -h(e) * n + parseInt(r(e, 'marginLeft'), 10) + e.offsetWidth / 1;
                        })(i, n) + 'px'),
                    (c.style.maxWidth = i.offsetWidth + 'px'),
                    (function (t, e) {
                        var n = t.querySelector('.' + v);
                        n && (n.style.left = 100 * h(e) + '%');
                    })(u, n));
            },
            startHovering: function () {
                l = !0;
            },
            stopHovering: function () {
                l = !1;
            },
            destroy: function () {
                return d(a);
            },
        };
    }
    var x = {};
    Object.defineProperty(x, '__esModule', {value: !0});
    var S = (x.throttle = void 0);
    S = x.throttle = function (t, e) {
        void 0 === e && (e = 0);
        var n,
            r = 0;
        return Object.assign(
            function () {
                for (var i = [], o = 0; o < arguments.length; o++) i[o] = arguments[o];
                var a = Math.max(0, r + e - Date.now());
                a
                    ? (clearTimeout(n),
                      (n = setTimeout(function () {
                          (r = Date.now()), t.apply(void 0, i);
                      }, a)))
                    : ((r = Date.now()), t.apply(void 0, i));
            },
            {
                cancel: function () {
                    (r = 0), clearTimeout(n);
                },
            },
        );
    };
    var T = 16,
        A = 'is-fully-scrolled',
        D = function (t) {
            return function (e) {
                var n = e.currentTarget,
                    r = -e.deltaY;
                r > 0 && f(t, A), n && r <= 0 && r < n.clientHeight + n.scrollTop - n.scrollHeight && s(t, A);
            };
        };
    var _ = 'littlefoot__content',
        M = 'littlefoot__wrapper',
        O = 'littlefoot--print',
        W = 'littlefoot',
        L = function () {
            for (var t = [], e = 0; e < arguments.length; e++) t[e] = arguments[e];
            return t.forEach(function (t) {
                return s(t, O);
            });
        };
    function C(t, e) {
        return Array.from(t.querySelectorAll(e));
    }
    function P(t, e) {
        return t.querySelector('.' + e) || t.firstElementChild || t;
    }
    function j(t) {
        var e = document.createElement('div');
        return (e.innerHTML = t), e.firstElementChild;
    }
    function R(t) {
        return void 0 !== t;
    }
    function z(t) {
        var e = t.parentElement,
            n = (function (t, e) {
                return Array.from(t.children).filter(function (t) {
                    return 8 !== t.nodeType && t.matches(e);
                });
            })(e, ':not(.' + O + ')'),
            r = n.filter(function (t) {
                return 'HR' === t.tagName;
            });
        n.length === r.length && (L.apply(void 0, r.concat(e)), z(e));
    }
    function k(t) {
        var e = t.parentElement;
        d(t), e.innerHTML.replace('[]', '').replace('&nbsp;', ' ').trim() || k(e);
    }
    function I(t, e) {
        var n = t[0],
            r = t[1],
            i = t[2],
            o = j(i.outerHTML);
        C(o, '[href$="#' + n + '"]').forEach(k);
        var a = o.innerHTML.trim();
        return [r, i, {id: String(e + 1), number: e + 1, reference: 'lf-' + n, content: a.startsWith('<') ? a : '<p>' + a + '</p>'}];
    }
    function q(t) {
        return function (e) {
            return t.replace(/<%=?\s*(\w+?)\s*%>/g, function (t, n) {
                var r;
                return String(null !== (r = e[n]) && void 0 !== r ? r : '');
            });
        };
    }
    function F(t, e) {
        var n = q(t),
            r = q(e);
        return function (t) {
            var e = t[0],
                i = t[1],
                o = i.id,
                a = j('<span class="'.concat(W, '">').concat(n(i), '</span>')),
                u = a.firstElementChild;
            u.setAttribute('aria-expanded', 'false'), (u.dataset.footnoteButton = ''), (u.dataset.footnoteId = o);
            var c = j(r(i));
            (c.dataset.footnotePopover = ''), (c.dataset.footnoteId = o);
            var l = P(c, M),
                s = P(c, _);
            return (
                (function (t, e) {
                    t.addEventListener('wheel', S(D(e), T));
                })(s, c),
                e.insertAdjacentElement('beforebegin', a),
                {id: o, button: u, host: a, popover: c, content: s, wrapper: l}
            );
        };
    }
    function B(t) {
        var n,
            r,
            i,
            o = t.allowDuplicates,
            a = t.anchorParentSelector,
            u = t.anchorPattern,
            c = t.buttonTemplate,
            l = t.contentTemplate,
            s = t.footnoteSelector,
            d = t.numberResetSelector,
            v = t.scope,
            m = (function (t, e, n) {
                return C(t, n + ' a[href*="#"]').filter(function (t) {
                    return (t.href + t.rel).match(e);
                });
            })(document, u, v)
                .map(
                    (function (t, e, n, r) {
                        var i = [];
                        return function (o) {
                            var a = o.href.split('#')[1],
                                u = C(t, '#' + window.CSS.escape(a)).find(function (t) {
                                    return e || !i.includes(t);
                                }),
                                c = null == u ? void 0 : u.closest(r);
                            if (c) {
                                i.push(c);
                                var l = o.closest(n) || o;
                                return [l.id || o.id, l, c];
                            }
                        };
                    })(document, o, a, s),
                )
                .filter(R)
                .map(I)
                .map(
                    d
                        ? ((n = d),
                          (r = 0),
                          (i = null),
                          function (t) {
                              var o = t[0],
                                  a = t[1],
                                  u = t[2],
                                  c = o.closest(n);
                              return (r = i === c ? r + 1 : 1), (i = c), [o, a, e(e({}, u), {number: r})];
                          })
                        : function (t) {
                              return t;
                          },
                )
                .map(function (t) {
                    var e = t[0],
                        n = t[1],
                        r = t[2];
                    return L(e, n), z(n), [e, r];
                })
                .map(F(c, l))
                .map(H);
        return {
            footnotes: m,
            unmount: function () {
                m.forEach(function (t) {
                    return t.destroy();
                }),
                    C(document, '.' + O).forEach(function (t) {
                        return f(t, O);
                    });
            },
        };
    }
    var V = {
        activateDelay: 100,
        activateOnHover: !1,
        allowDuplicates: !0,
        allowMultiple: !1,
        anchorParentSelector: 'span',
        anchorPattern: /(fn|footnote|note)[:\-_\d]/gi,
        dismissDelay: 100,
        dismissOnUnhover: !1,
        dismissOnDocumentTouch: !0,
        footnoteParentClass: 'footnote',
        footnoteSelector: 'li',
        hoverDelay: 250,
        numberResetSelector: '',
        scope: '',
        contentTemplate: '<aside class="littlefoot__popover" id="fncontent:<% id %>"><div class="'.concat(M, '"><div class="').concat(_, '"><% content %></div></div><div class="').concat(v, '"></div></aside>'),
        buttonTemplate: '<button type="button" class="littlefoot__button" id="<% reference %>" aria-label="See Footnote <% number %>"><%= number %></button>',
    };
    var N = 16,
        U = '[data-footnote-button]',
        Y = '[data-footnote-id]',
        $ = '[data-footnote-popover]',
        G = function (t, e) {
            return t.target.closest(e);
        },
        J = function (t) {
            return null == t ? void 0 : t.dataset.footnoteId;
        },
        K = function (t) {
            return function (e) {
                e.preventDefault();
                var n = G(e, Y),
                    r = J(n);
                r && t(r);
            };
        },
        Q = document.addEventListener,
        X = window.addEventListener,
        Z = function (t, e, n, r) {
            Q(
                t,
                function (t) {
                    var r = t.target;
                    (null == r ? void 0 : r.closest(e)) && n.call(r, t);
                },
                r,
            );
        };
    function tt(t) {
        void 0 === t && (t = {});
        var n = e(e({}, V), t),
            r = (function (t, e) {
                var n = t.footnotes,
                    r = t.unmount,
                    i = function (t) {
                        return function (n) {
                            n.isReady() && (n.dismiss(e.dismissCallback), setTimeout(n.remove, t));
                        };
                    },
                    o = function (t) {
                        return function (r) {
                            e.allowMultiple ||
                                n
                                    .filter(function (t) {
                                        return t.id !== r.id;
                                    })
                                    .forEach(i(e.dismissDelay)),
                                r.isReady() && (r.activate(e.activateCallback), r.reposition(), r.resize(), setTimeout(r.ready, t));
                        };
                    },
                    a = function (t) {
                        return function (e) {
                            var r = n.find(function (t) {
                                return t.id === e;
                            });
                            r && t(r);
                        };
                    },
                    u = function () {
                        return n.forEach(i(e.dismissDelay));
                    };
                return {
                    activate: function (t, e) {
                        return a(o(e))(t);
                    },
                    dismiss: function (t, e) {
                        return a(i(e))(t);
                    },
                    dismissAll: u,
                    touchOutside: function () {
                        e.dismissOnDocumentTouch && u();
                    },
                    repositionAll: function () {
                        return n.forEach(function (t) {
                            return t.reposition();
                        });
                    },
                    resizeAll: function () {
                        return n.forEach(function (t) {
                            return t.resize();
                        });
                    },
                    toggle: a(function (t) {
                        return t.isActive() ? i(e.dismissDelay)(t) : o(e.activateDelay)(t);
                    }),
                    hover: a(function (t) {
                        t.startHovering(), e.activateOnHover && !t.isActive() && o(e.hoverDelay)(t);
                    }),
                    unhover: a(function (t) {
                        t.stopHovering(),
                            e.dismissOnUnhover &&
                                setTimeout(function () {
                                    return n
                                        .filter(function (t) {
                                            return !t.isHovered();
                                        })
                                        .forEach(i(e.dismissDelay));
                                }, e.hoverDelay);
                    }),
                    unmount: r,
                };
            })(B(n), n),
            i = (function (t) {
                var e = function (e) {
                        var n = G(e, U),
                            r = J(n);
                        r ? t.toggle(r) : G(e, $) || t.touchOutside();
                    },
                    n = S(t.repositionAll, N),
                    r = S(t.resizeAll, N),
                    i = K(t.hover),
                    o = K(t.unhover),
                    a = new AbortController(),
                    u = a.signal;
                return (
                    Q('touchend', e, {signal: u}),
                    Q('click', e, {signal: u}),
                    Q(
                        'keyup',
                        function (e) {
                            (27 !== e.keyCode && 'Escape' !== e.key && 'Esc' !== e.key) || t.dismissAll();
                        },
                        {signal: u},
                    ),
                    Q('gestureend', n, {signal: u}),
                    X('scroll', n, {signal: u}),
                    X('resize', r, {signal: u}),
                    Z('mouseover', Y, i, {signal: u}),
                    Z('mouseout', Y, o, {signal: u}),
                    function () {
                        a.abort();
                    }
                );
            })(r);
        return {
            activate: function (t, e) {
                void 0 === e && (e = n.activateDelay), r.activate(t, e);
            },
            dismiss: function (t, e) {
                void 0 === e && (e = n.dismissDelay), void 0 === t ? r.dismissAll() : r.dismiss(t, e);
            },
            unmount: function () {
                i(), r.unmount();
            },
            getSetting: function (t) {
                return n[t];
            },
            updateSetting: function (t, e) {
                n[t] = e;
            },
        };
    }
    (t.default = tt), (t.littlefoot = tt), Object.defineProperty(t, '__esModule', {value: !0});
});
