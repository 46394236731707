// Shared scripts added to landing pages
'use strict';

// Hide Header/Nav on on scroll down variables
var mainContents = $('main'),
    stickyAlwaysNav = $('.sticky-always-nav'),
    stickyAlwaysNavHeight = stickyAlwaysNav.outerHeight(),
    stickyNav = $('.sticky-nav'),
    stickyNavHeight = stickyNav.outerHeight(),
    viewTranscriptClick = $('.js-view-transcript');
var autoscrolled;
var delta = 50;
var didScroll;
var lastScrollTop = 0;

function hasScrolled() {
    var scrollTop = $(window).scrollTop();

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - scrollTop) <= delta) return;
    // This is necessary so you never see what is "behind" the site header.

    if (stickyNav.length && scrollTop > lastScrollTop && scrollTop) {
        // when scrolling down, pull up site header
        stickyNav.removeClass('sticky-nav--sticky sticky-nav--down');

        mainContents.removeClass('main-contents--pushed').css({
            'margin-top': 'auto',
        });
    } else if (scrollTop && !autoscrolled) {
        stickyNav.addClass('sticky-nav--sticky sticky-nav--down');

        mainContents.addClass('main-contents--pushed').css({
            'margin-top': stickyNavHeight,
        });
    }

    lastScrollTop = scrollTop;
}

function stickyAlwaysNavApply() {
    if (stickyAlwaysNav.length) {
        mainContents.addClass('main-contents--pushed').css({
            'margin-top': stickyAlwaysNavHeight,
        });
    }
}

function disableStickyNavOnBodyLinkClicks() {
    $('a').on('click', function () {
        autoscrolled = true;

        // remove sticky header
        stickyNav.removeClass('sticky-nav--sticky sticky-nav--down');

        // remove pushing of main-contents
        mainContents.removeClass('main-contents--pushed').css({});
    });
}

function footnotesLittlefoot() {
	// https://littlefoot.js.org/
	var littlefootOptions = {
		activateOnHover: false, // Specifies whether or not the footnote content will be activated when the associated button is hovered over.
		allowDuplicates: true, // Determines whether or not a footnote can be used as the content for multiple footnote buttons.
		allowMultiple: false, // Specifies whether or not multiple footnote popovers can be active simultaneously.
		dismissOnUnhover: false, // Determines whether footnotes that were presented when hovering on a footnote button are dismissed once the footnote button or footnote popover is un-hovered.
		hoverDelay: 100,
		preventPageScroll: false,
	};
	littlefoot.default(littlefootOptions);

	$('.sup-footnote a[data-footnote-backlink-ref]').attr('aria-hidden', 'true');
}

function footnotesReset() {
	// logic to remove all spans with .littlefoot
	littlefoot.littlefoot().unmount(),
    document.querySelectorAll('span.littlefoot').forEach(e => e.remove()),
    footnotesLittlefoot()
}

function lazyloadYouTubeSD() {
    // https://webdesign.tutsplus.com/tutorials/how-to-lazy-load-embedded-youtube-videos--cms-26743
    var youtube = document.querySelectorAll('.youtube.sdres');

    // loop
    for (var i = 0; i < youtube.length; i++) {
        // thumbnail image source: use SD default (640x480)
        var source = 'https://img.youtube.com/vi/' + youtube[i].dataset.embed + '/sddefault.jpg';

        // Load the image asynchronously
        var image = new Image();
        image.src = source;
        image.addEventListener(
            'load',
            (function () {
                youtube[i].appendChild(image);
                image.setAttribute('alt', '');
            })(i),
        );

        // Add click event to load video
        youtube[i].addEventListener('click', function () {
            var iframe = document.createElement('iframe');
            iframe.setAttribute('frameborder', '0');
            iframe.setAttribute('allowfullscreen', '');
            iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
            iframe.setAttribute('src', 'https://www.youtube.com/embed/' + this.dataset.embed + '?rel=0&showinfo=0&autoplay=1');

            this.innerHTML = '';
            this.appendChild(iframe);

            /* Output will look like the following
			<iframe src="https://www.youtube.com/embed/d-WwvSgHkIs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			*/
        });
    }
}

function lazyloadYouTubeMaxRes() {
    // https://webdesign.tutsplus.com/tutorials/how-to-lazy-load-embedded-youtube-videos--cms-26743
    var youtube = document.querySelectorAll('.youtube.maxres');

    // loop
    for (var i = 0; i < youtube.length; i++) {
        // thumbnail image source: use MaxResolution default (1280x720)
        var source = 'https://img.youtube.com/vi/' + youtube[i].dataset.embed + '/maxresdefault.jpg';

        // Load the image asynchronously
        var image = new Image();
        image.src = source;
        image.addEventListener(
            'load',
            (function () {
                youtube[i].appendChild(image);
                image.setAttribute('alt', '');
            })(i),
        );

        // Add click event to load video
        youtube[i].addEventListener('click', function () {
            var iframe = document.createElement('iframe');
            iframe.setAttribute('frameborder', '0');
            iframe.setAttribute('allowfullscreen', '');
            iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
            iframe.setAttribute('src', 'https://www.youtube.com/embed/' + this.dataset.embed + '?rel=0&showinfo=0&autoplay=1');

            this.innerHTML = '';
            this.appendChild(iframe);

            /* Output will look like the following
			<iframe src="https://www.youtube.com/embed/d-WwvSgHkIs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
			*/
        });
    }
}

function videoTranscript() {
    // for each js-view-transcript
    viewTranscriptClick.each(function () {
        $(this).on('click', function (e) {
            // prevent default behavior
            e.preventDefault();

            // update copy when clicked to show/hide transcript
            $(this).text($(this).text() == 'Hide Transcript' ? 'View Transcript' : 'Hide Transcript');

            // go up to parent, add 'show' class to display transcript copy
            $(this).parents('.video-caption').toggleClass('show');
        });
    });
}

$(document).ready(function () {
    stickyAlwaysNavApply();

    // set interval so function doesn't run continously
    setInterval(function () {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        } else if (autoscrolled) {
            autoscrolled = false;
        }
    }, 250);

    disableStickyNavOnBodyLinkClicks();
    footnotesReset();
	lazyloadYouTubeSD();
	lazyloadYouTubeMaxRes();
    videoTranscript();
});

// on page scroll, run function
$(window).on('scroll', function () {
    didScroll = true;
});
